import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import AccordionContent from './AccordionContent'
import MainInfo from './MainInfo'
import Diseases from './Diseases'
import Deadline from './Deadline'
import Genes from './Genes'
import * as S from './styled'
import * as F from '../shared/fragments/exam-fragments'
import CopyButton from './CopyButton'
import ModalGenes from './ModalGenes'
import Img from 'gatsby-image'
import ImgGeneticExam from './ImgGeneticExam'
import useIsBrazil from '../../utils/isBrazil'

const SingleExam = ({ exam, data }) => {
  const pageData = data.wordpressWpGatsbyPage.acf
  const showAns = useIsBrazil()

  return (
    <Fragment>
      <S.SingleContainer>
        <S.SingleExamContainer>
          <MainInfo exam={exam} data={pageData} />
    
          <S.SingleExamContent>
            <S.ColumnExam>
              <S.ColumnExamContent>
                <Diseases exam={exam} data={pageData} />
                <Deadline exam={exam} data={pageData} />

                {showAns && (
                  exam.ansCover 
                  ?
                    <F.AnsCover data={pageData} />
                  :
                    <F.AnsNotCover data={pageData} />
                )}

                {exam.genes && 
                  <Genes exam={exam} data={pageData} />
                }

                <S.ButtonsDiv>
                  <CopyButton exam={exam} data={pageData} />

                  {exam.genes.length > 20 && (
                    <ModalGenes exam={exam} data={pageData} />
                  )}
                </S.ButtonsDiv>
              </S.ColumnExamContent>
            </S.ColumnExam>
    
            <S.ColumnDetails>
              <AccordionContent 
                exam={exam}
                data={data}
                singleExam 
              />
            </S.ColumnDetails>
          </S.SingleExamContent>
        </S.SingleExamContainer>
        <S.ImgGeneticContainer>
            {pageData.imagem_detalhamento_exame
            ?
              <Img 
                fluid={pageData.imagem_detalhamento_exame.localFile.childImageSharp.fluid}
                alt={pageData.imagem_detalhamento_exame.alt_text}
              />
            :
              <ImgGeneticExam />
            }
        </S.ImgGeneticContainer>
      </S.SingleContainer>
      <S.LinePink />
    </Fragment>
  )
}

SingleExam.propTypes = {
  exam: PropTypes.object.isRequired,
  edges: PropTypes.array,
  location: PropTypes.object,
  pageContext: PropTypes.object.isRequired,
}

export default SingleExam
